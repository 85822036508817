<script setup>
const dialogVisible = ref(true);

// Copy
const copied = ref(false);
const copyToClipboard = () => {
    const textToCopy = 'FREEDAY';
    navigator.clipboard.writeText(textToCopy)
    copied.value = true
}

const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)

//close
const close = () => {
    document.getElementById('ann').classList.add('hidden')
}
</script>
<template>
    <div class="">
        <div class="pa-4 text-center mb-3">
            <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                <v-dialog transition="scroll-y-transition" :close-delay="0" theme="dark" opacity="0" width="95vw"
                    class="bg-zinc-950 bg-opacity-50 backdrop-blur-sm" max-width="95vw">
                    <template v-slot:activator="{ props: activatorProps }">
                        <div id="ann" class="relative flex justify-center bg-zinc-900 rounded-sm border-2"
                            style="border-image: linear-gradient(65deg, #00e5de, #f2004b) 1;">
                            <div class="p-3">
                                <h2 class="p-3 font-semibold text-lg">{{ $t('showmetitle') }}
                                </h2>
                                <v-btn name="showaccouncement" aria-label="show" @click="" v-bind="activatorProps"
                                    type="button" variant="tonal" color="#f2004b" :ripple="false" :elevation="1"
                                    class="my-auto aw-20">
                                    {{ $t('showme') }}
                                </v-btn>
                            </div>
                            <div class="absolute top-0 right-0 p-2">
                                <!-- <v-btn variant="text" icon @click="closeFunction" :ripple="false"> -->
                                <v-icon size="20" class="hover:cursor-pointer" name="closeannoumcement"
                                    aria-label="close" @click="close">mdi-close</v-icon>
                                <!-- </v-btn> -->
                            </div>
                        </div>

                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card>
                            <template v-slot:text>
                                <!-- <v-card-title class="text-center"> -->
                                <h1 class="font-bold md:text-xl mb-5 pa-2">{{ $t('showmetitle2') }}</h1>
                                <!-- </v-card-title> -->
                                <h1 class="text-xl font-weight-bold mb-3 text-center  font-sans font-bold">
                                    <!-- {{ $t('subscancel') }} -->

                                </h1>
                                <!-- <v-card-subtitle> -->
                                <p class="text-sm opacity-70 pa-2 px-5">
                                    {{ $t('showmetitle3') }}
                                </p>
                                <!-- </v-card-subtitle> -->
                                <v-card-text>
                                    <h2 class="headline py-3">{{ $t('showmeguide1') }}</h2>
                                    <ol class="px-5 space-y-3 list-decimal">
                                        <li><strong class="p-1">{{ $t('showmeguide2') }}</strong> {{
                                            $t('showmeguide2desc')
                                            }}</li>
                                        <li><strong class="p-1">{{ $t('showmeguide3') }}</strong>{{
                                            $t('showmeguide3desc')
                                            }}
                                        </li>
                                        <li><strong class="p-1">{{ $t('showmeguide4') }}</strong>{{
                                            $t('showmeguide4desc')
                                            }}<v-chip class="mx-1 font-weight-bold text-caption" color="red"
                                                text-color="white">FREEDAY<v-icon @click="copyToClipboard" size="15"
                                                    class="mx-2">{{ copied ? 'mdi-check' : 'mdi-content-copy'
                                                    }}</v-icon></v-chip> {{ $t('showmeguide4desc2') }}</li>
                                        <li><strong class="p-1">{{ $t('showmeguide5') }}</strong> {{
                                            $t('showmeguide5desc')
                                            }}</li>
                                    </ol>
                                </v-card-text>
                                <v-card-actions>
                                    <div class="p-3 ">
                                        <h2 class="font-bold">{{ $t('contactsupporttitle') }}</h2>
                                        <nuxt-link to="/GuideMe" target="_blank" rel="noopener noreferrer"
                                            class="p-1 text-sm hover:underline">{{
                                                $t('guidemepage') }} <v-icon size="15">mdi-open-in-new</v-icon></nuxt-link>
                                        <v-btn color="secondary"
                                            href="mailto:support@chattokgaming.com?subject=I need help with my account"
                                            target="_blank" rel="noopener noreferrer">{{
                                                $t('contactsupport')
                                            }}</v-btn>
                                    </div>
                                </v-card-actions>
                                <!-- <v-card-subtitle> -->
                                <div class="px-4 opacity-80 text-sm space-y-1">
                                    <h2 class="headline font-black">{{ $t('promotitle') }}</h2>
                                    <p><strong>{{ $t('promotitle2') }}</strong> FREEDAY</p>
                                    <p><strong>{{ $t('promotitle3') }}</strong> {{ $t('promotitle3desc') }}</p>
                                    <p><strong>{{ $t('promotitle4') }}</strong> TBA</p>
                                    <p><strong>{{ $t('promotitle5') }}</strong> {{ $t('promotitle5desc') }}</p>
                                </div>
                                <!-- </v-card-subtitle> -->
                                <v-spacer></v-spacer>
                            </template>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn name="showaccouncement" aria-label="show" color="surface-variant" text=""
                                    variant="text" @click="isActive.value = false">{{
                                        $t('close') }}</v-btn>

                                <!-- <v-btn color="red" :elevation="4" text="" variant="outlined"
                                @click="isActive.value = false;">{{ $t('confirm')
                                }}</v-btn> -->
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-locale-provider>
        </div>
    </div>
</template>