<script setup>

</script>
<template>
    <div>
        <div class="main flex w-11/12 mx-auto px-5 -mt-5 mb-2">
            <v-icon size="30" color="#ff0050">mdi-racing-helmet</v-icon>
            <div class="w-full my-auto">
                <div class="w-11/12 h-0.5 bg-[#ff0050] m-1"></div>
                <div class="w-9/12 h-0.5 bg-[#00f2ea] m-1"></div>
                <div class="w-2/3  h-0.5 bg-slate-300 m-1"></div>
            </div>
        </div>
    </div>
</template>