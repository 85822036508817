<script setup>
const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)
</script>
<template>
    <div id="about" class="about" data-aos="fade-up" data-aos-duration="500">
        <div class="1">
            <v-lazy>
                <transition name="fade" mode="out-in">
                    <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                        <v-parallax src="/mainicon.ico" placeholder="/mainicon.ico" cover
                            class="skills xl:h-[42rem] lg:h-[40rem] h-fit xl:p-20 p-10 ah-fit mx-auto items-center"
                            gradient="to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)">
                            <div class="md:text-3xl text-2xl flex mx-auto w-fit justify-center items-center font-bold md:ml-[0.4rem] mt-5"
                                data-aos="fade-up" data-aos-duration="500">
                                <v-icon class="mx-3a mb-10 my-auto" size="30">mdi-cash</v-icon>
                                <h1 class="my-auto text-center w-fit mb-10 px-2">{{ $t('makemoneychattok') }}</h1>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1000"
                                class="d-flex lg:flex-row flex-col-reverse items-center gap-4 md:w-11/12 max-w-[102rem] mx-auto ">
                                <div data-aos="fade-up" data-aos-duration="500" class="lg:w-11/12 mx-auto">
                                    <EarningCalc />
                                </div>
                                <div
                                    class="lg:w-2/3a w-fit mx-auto text-centera py-8 h-full my-auto backdrop-brightness-50 outline outline-[#ff0050] shadow-md shadow-[#ff0050]/50 rounded-sm">
                                    <p
                                        class="md:ml-12a ml-5a py-5 px-3 font-black text-center mx-auto brightness-90 md:text-lg text-sm mt-2 leading-snug ">
                                        {{ $t('makemoneychattokdesc') }}:
                                    </p>
                                    <div
                                        class="md:ml-[3.5rem] aml-5 py-3 space-y-5 lg:w-8/12a mx-auto  p-5 md:text-lg text-md mt-2 leading-snug ">
                                        <ol class="space-y-5 text-md list-decimal p-3 md:ml-10 ml-3">
                                            <li>
                                                <p class="font-semibold inline-block m-1">{{ $t('makemoneychattok1') }}:
                                                </p>
                                                {{ $t('makemoneychattok1desc') }}
                                            </li>
                                            <li>
                                                <p class="font-semibold inline-block m-1">{{ $t('makemoneychattok2') }}:
                                                </p>
                                                {{ $t('makemoneychattok2desc') }}.
                                            </li>

                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </v-parallax>
                    </v-locale-provider>
                </transition>
            </v-lazy>
        </div>
    </div>
</template>