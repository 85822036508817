<script setup>
const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)

// handle Auth state
const user = useSupabaseUser()
const isUser = ref(true)
watch(user, () => {
    if (user.value) {
        isUser.value = true
    } else {
        isUser.value = false
    }
}, { immediate: true })
</script>
<template>
    <v-lazy>
        <transition name="fade" mode="out-in">
            <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                <v-parallax src="" class="lg:h-[10rem] h-fit" alt="Car1">
                    <div class="d-flex md:flex-row flex-col-reverse md:ml-[8.6rem]a afill-height justify-center"
                        data-aos="fade-up" data-aos-duration="1500">
                        <h1 id="offers"
                            class="lg:text-4xl text-2xl font-bold p-5 drop-shadow-[0px_1px_30px_rgba(255,0,80,1)] lg:mt-[2rem] mt-[2rem] mb-[2rem] mx-auto text-center italic"
                            data-aos="fade-up" data-aos-duration="500">{{ $t('discovergame') }}</h1>
                    </div>
                </v-parallax>
            </v-locale-provider>
        </transition>
    </v-lazy>


</template>
<script>
// import Swal from 'sweetalert2'

export default {
    data() {
        return {
            sale_items: true,
            items: true,
            settings: null,
        }
    },
    mounted() {
        // this.fetchProducts();
    },
    methods: {

        // async fetchProducts() {
        //     const supabase = useSupabaseClient()
        //     const user = useSupabaseUser()
        //     try {
        //         const { data, error } = await supabase.from('Products').select('*');
        //         const { data: config, error: configerror } = await supabase
        //             .from('store_config')
        //             .select('*')

        //         this.settings = config[0]
        //         // console.log('Products:', data);
        //         this.items = data

        //     } catch (error) {
        //         console.error('Error fetching products:', error.message);
        //     }
        // },

    },
}

</script>

<style></style>