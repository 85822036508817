<script setup>
const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)
</script>
<template>
    <div>
        <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
            <v-lazy>
                <transition name="fade" mode="out-in">
                    <div
                        class="d-flex md:flex-row flex-col-reverse bg-[url('/blob.svg')] bg-cover justify-center amd:space-x-72 w-full mx-auto items-center md:p-10 md:m-5 md:min-h-[50rem] min-h-[50rem]">
                        <div
                            class="d-flex flex-column amd:ml-[8.6rem] lg:m-0 m-5 p-2a afill-height justify-center items-center alaign-center text-white">

                            <div class="rounded-md backdrop-brightness-50 bg-opacity-7a5 bga-zinc-900  items-center text-center lg:w-[35rem]  md:m-10 md:p-10 p-5 "
                                data-aos="fade-up" data-aos-duration="500">
                                <h1 class="text-2xl md:text-3xl w-full font-weight-bold mb-4">
                                    {{
                                        $t('welcomechattok') }}
                                </h1>
                                <div class="brand flex w-fit m-auto justify-center  ">
                                    <p class="text-[#ff0050] my-auto inline-block px-2 md:text-4xl text-2xl">
                                        Chattok</p>
                                    <p class="inline-block text-[#00f2ea] my-auto md:text-4xl text-2xl">Gaming</p>


                                    <v-img src="/public/mainicon.ico" alt="chattokicon" width="40" height="40"
                                        class="inline-block my-auto w-fit ml-5 mr-5"></v-img>
                                </div>
                                <p
                                    class="md:ml-14a ml-5a py-3 w-fit mx-auto text-center brightness-75 md:text-lg text-md mt-5 leading-snug ">
                                    {{
                                        $t('welcomechattokdesc') }}
                                </p>
                                <v-btn to="/login" color="#ff0050" variant="flat" :ripple="false" class="text-h6 m-2">{{
                                    $t('chattoklivebutton') }}</v-btn>

                            </div>
                        </div>
                        <div class="md:flex flex-col space-y-4 w-fit bg-zianc-900 justify-center items-center md:p-5 p-2 amx-auto afill-height"
                            adata-aos="fade-up" adata-aos-duration="2000">
                            <video id="my-video"
                                class="video-js lg:w-[16rem] w-[10rem] min-w-[10rem] h-full p-2 rounded-xl" controlsa
                                autoplay="true" width="100%" playsinline onloadstart="this.volume=0.5" muted loop="true"
                                height="100%" data-poster="/mainicon.ico" data-setup="{}" v-lazy-load>
                                <source src="/intro.mp4" type="video/mp4" />
                                <p class="vjs-no-js">
                                    To view this video please enable JavaScript, and consider upgrading to a
                                    web browser that
                                    <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5
                                        video</a>
                                </p>
                            </video>
                        </div>
                    </div>
                </transition>
            </v-lazy>
        </v-locale-provider>
    </div>
</template>
