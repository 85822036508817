<script setup>
const { locale, setLocale } = useI18n()
const currentLocale = computed(() => locale.value)
</script>
<template>
    <div>
        <v-lazy>
            <transition name="fade" mode="out-in">
                <div id="about" class="about bg-zinc-95 w-full rounded-sm min-h-[50rem]">
                    <v-lazy>
                        <transition name="fade" mode="out-in">
                            <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                                <v-parallax src="/racing_line.webp" placeholder="/mainicon.ico" alt="carrace" cover
                                    width="100%" class="aboutme mx-auto w-fit xl:h-[40rem] p-5 m-1 rounded-sm"
                                    gradient="to right, rgba(255, 0, 80, 0.4), rgba(0, 0, 0, 0.0)),linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 242, 234, 0.4)),linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)">

                                    <div class="md:text-3xl text-2xl font-medium d-flex w-fit justify-center mx-auto items-center text-center  md:p-5 p-3"
                                        data-aos="fade-up" data-aos-duration="500">
                                        <h2 class="text-3xl font-semibold py-5 my-auto text-center mx-auto md:min-w-full"
                                            data-aos="fade-up" data-aos-duration="500">
                                            <v-icon class="md:mx-3 my-auto mb-2" size="35">mdi-broadcast</v-icon>
                                            TikTok Live Games
                                        </h2>
                                    </div>
                                    <p
                                        class="md:ml-[4rem]a ml-5a -mt-4 mb-5 lg:w-2/3 text-center mx-auto brightness-75 md:text-lg text-md hmt-5 leading-snug ">
                                        {{ $t('choosechattokdesc') }}
                                    </p>
                                    <v-lazy>
                                        <transition name="fade" mode="out-in">
                                            <div class="games bg-zinc-950 md:flex flex-wrap gap-4 w-fit  md:max-w-fit mx-auto backdrop-blur-lg justify-around md:gap-14  md:space-y-0 space-y-10 md:ml-3 p-10 shadow-sm shadow-zinc-900/50 rounded-md border-2 border-zinc-800"
                                                adata-aos="fade-up" adata-aos-duration="1000">
                                                <div class="1 flex flex-col items-center py-2 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/street_racing.webp"
                                                            class="md:w-[10rem] max-w-[10rem] min-w-[10rem] h-[10rem] min-h-[10rem] max-h-[10rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <!-- <h2 class="text-xl p-1 font-semibold">in BETA</h2> -->
                                                    <p class="px-1 mt-2 opacity-80">Street
                                                        Racing</p>
                                                    <button @click="navigateTo('/login')"
                                                        class="text-center bg-[#f2004be6] p-1.5 rounded-md shadow-sm shadow-zinc-900/50 hover:scale-95 hover:outline-2 outline-offset-1 outline-transparent outline hover:outline-[#00e5dee6]/80 mx-auto mt-2 m-2 my-auto max-w-fit w-fit">
                                                        {{ $t('golive') }}</button>
                                                </div>
                                                <div class="2 flex flex-col items-center py-2 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/zombie_hunters.webp"
                                                            class="md:w-[10rem] max-w-[10rem] min-w-[10rem] h-[10rem] min-h-[10rem] max-h-[10rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <!-- <h2 class="text-xl p-1 font-semibold">Soon</h2> -->
                                                    <p class="px-1 mt-2 opacity-80">Zombie
                                                        Haunters</p>
                                                    <v-btn disabled color=""
                                                        class="text-center text-white mx-auto mt-2 m-2 my-auto max-w-fit w-fit">
                                                        {{ $t('soon') }}</v-btn>
                                                </div>
                                                <div class="3 flex flex-col items-center py-2 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/trivia.webp"
                                                            class="md:w-[10rem] max-w-[10rem] min-w-[10rem] h-[10rem] min-h-[10rem] max-h-[10rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <!-- <h2 class="text-xl p-1 font-semibold">Soon</h2> -->
                                                    <p class="px-1 mt-2 opacity-80">Trivia</p>
                                                    <v-btn disabled color=""
                                                        class="text-center text-white mx-auto mt-2 m-2 my-auto max-w-fit w-fit">
                                                        {{ $t('soon') }}</v-btn>
                                                </div>
                                            </div>
                                        </transition>
                                    </v-lazy>
                                </v-parallax>
                            </v-locale-provider>
                        </transition>
                    </v-lazy>
                    <div class="howitworks">
                        <v-lazy>
                            <transition name="fade" mode="out-in">
                                <v-locale-provider :rtl="currentLocale == 'ar' ? true : false">
                                    <v-parallax src="" cover max-height="auto" min-height="400" height="auto"
                                        class="aboutme mx-auto lg:px-40 md:py-20 p-5 m-1 rounded-sm  mt-5 mb-5"
                                        gradient="to right, rgba(255, 0, 80, 0.4), rgba(0, 0, 0, 0.0)),linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 242, 234, 0.4)),linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)">
                                        <div class="md:text-3xl text-2xl font-medium flex w-fit mx-auto md:-ml-[0.8rem] md:p-5 p-3"
                                            data-aos="fade-up" data-aos-duration="500">
                                            <v-icon class="mx-3 my-auto" size="35">mdi-sitemap</v-icon>
                                            <h1
                                                class="my-auto text-center w-full min-w-fit mr-5 font-semibold brightness-200 ">
                                                {{
                                                    $t('workchattok') }}</h1>
                                        </div>
                                        <iframe
                                            class="lg:w-[50rem] md:w-[45rem] md:h-[30rem] h-[18rem] flex justify-center items-center mx-auto m-3"
                                            width="100%" height="100%"
                                            :src="currentLocale == 'ar' ? 'https://www.youtube.com/embed/ByoIqFLLAqs?si=8Trdy3ItfcCeb0wt' : 'https://www.youtube.com/embed/Xj5ad-7tVa0?si=7ScJFwKh4IuI2S8h'"
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                                            loading="lazy"></iframe>
                                        <!-- <div
                                class="md:ml-14 py-3 space-y-5 lg:w-2/3 brightness-90 md:text-lg text-sm mt-5 min-w-fit leading-snug ">
                                <ol class="space-y-5 text-md list-decimal p-3 md:ml-10 ml-3">
                                    <li>
                                        <p class="font-semibold inline-block m-1">{{
                                            $t('workchattokplan1') }}:</p>
                                        {{ $t('workchattokplan1desc') }}
                                    </li>
                                    <li>
                                        <p class="font-semibold inline-block m-1">{{ $t('workchattokplan2') }}:</p>
                                        {{ $t('workchattokplan2desc') }}.
                                    </li>

                                </ol>
                            </div> -->

                                    </v-parallax>
                                </v-locale-provider>
                            </transition>
                        </v-lazy>
                    </div>
                    <div class="team">
                        <v-lazy>
                            <transition name="fade" mode="out-in">

                                <v-parallax src="/ffflux.svg" class="xl:h-[42rem]" width="100%" cover>
                                    <div
                                        class="xl:p-40 p-5 max-w-fit min-w-fit mx-auto items-center d-flex fill-height">
                                        <div class="main">
                                            <h2 class="text-3xl font-semibold py-5 my-auto text-center md:min-w-full"
                                                data-aos="fade-up" data-aos-duration="500">
                                                <v-icon class="md:mx-3 my-auto mb-2"
                                                    size="35">mdi-account-group</v-icon>
                                                {{ $t('meetourteam') }}
                                            </h2>
                                            <div class="images bg-zinc-900 bg-opacity-50 md:flex flex-wrap gap-4 w-fit md:min-w-full mx-auto backdrop-brightness-110 backdrop-blur-axl shadow-sm rounded-md justify-around md:space-x-3 md:space-y-0 space-y-10 md:ml-3 p-10"
                                                data-aos="fade-up" data-aos-duration="1000">
                                                <div class="1 flex flex-col items-center py-5 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/Clinton.webp"
                                                            class="md:w-[16rem] max-w-[16rem] min-w-[16rem] h-[16rem] min-h-[16rem] max-h-[16rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <h2 class="text-xl p-1 font-semibold">Clinton</h2>
                                                    <p class="px-1 opacity-80">Chattok Gaming Founder</p>
                                                </div>
                                                <div class="2 flex flex-col items-center py-5 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/Jose.webp"
                                                            class="md:w-[16rem] max-w-[16rem] min-w-[16rem] h-[16rem] min-h-[16rem] max-h-[16rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <h2 class="text-xl p-1 font-semibold">Jose</h2>
                                                    <p class="px-1 opacity-80">Chattok Gaming Backdoor</p>
                                                </div>
                                                <div class="3 flex flex-col items-center py-5 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/Mory.webp"
                                                            class="md:w-[16rem] max-w-[16rem] min-w-[16rem] h-[16rem] min-h-[16rem] max-h-[16rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <h2 class="text-xl p-1 font-semibold">Mory</h2>
                                                    <p class="px-1 opacity-80">Chattok Gaming Developer</p>
                                                </div>
                                                <div class="4 flex flex-col items-center py-5 md:py-0">
                                                    <v-lazy>
                                                        <v-img src="/Alfa.webp"
                                                            class="md:w-[16rem] max-w-[16rem] min-w-[16rem] h-[16rem] min-h-[16rem] max-h-[16rem] rounded-md transition-all ease-in-out hover:-translate-y-2"
                                                            cover></v-img></v-lazy>
                                                    <h2 class="text-xl p-1 font-semibold">Alfa</h2>
                                                    <p class="px-1 opacity-80">Chattok Gaming Web Developer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-parallax>

                            </transition>
                        </v-lazy>
                    </div>
                </div>
            </transition>
        </v-lazy>
    </div>
</template>
<style>
.games {
    background: linear-gradient(45deg, rgba(0, 229, 222, 0.9), rgba(242, 0, 75, 0.9));
    background-size: 200% 200%;
    animation: borderAnimation 5s linear infinite;
}

@keyframes borderAnimation {
    0% {
        background-position: 0%;
    }

    50% {
        background-position: 80%;
    }

    100% {
        background-position: 0%;
    }
}
</style>
